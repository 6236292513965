import { clsx } from "clsx";
import { motion } from "framer-motion";

import { TaskModel, completeTask } from "entities/tasks";

import styles from "./task-checkbox.module.scss";

interface Props {
  task: TaskModel;
}

export function TaskCheckbox({ task }: Props) {
  if (!task.isCompletable) return null;

  return (
    <motion.div tabIndex={-1} whileTap={{ scale: 1.3 }}>
      <svg
        viewBox="0 0 16 16"
        className={clsx(styles.checkbox, {
          [styles.completed]: task.isCompleted,
        })}
        onClick={async () =>
          await completeTask(
            task.task,
            task.isCompleted ? undefined : new Date(),
          )
        }
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <path
          className={styles.square}
          d="M2 0h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
        />
        {task.isCompleted && (
          <path
            className={styles.checkmark}
            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"
          />
        )}
      </svg>
    </motion.div>
  );
}
