import { v4 as uuid } from "uuid";

import { ZoneEntity, database, getDefaultColor } from "shared/database";

export async function updateZone(
  zone: ZoneEntity,
  changes: {
    name?: string;
    color?: string;
    dayMinutesLimit?: number | null;
    weekMinutesLimit?: number | null;
  },
) {
  await database.zones.update(zone.id, changes);
}

export async function deleteZone(zone: ZoneEntity) {
  await database.zones.delete(zone.id);
}

export async function createEmptyZone() {
  await database.zones.add({
    id: uuid(),
    name: "Новая зона",
    color: getDefaultColor(),
    dayMinutesLimit: null,
    weekMinutesLimit: null,
    mondayMinutesLimit: null,
    tuesdayMinutesLimit: null,
    wednesdayMinutesLimit: null,
    thursdayMinutesLimit: null,
    fridayMinutesLimit: null,
    saturdayMinutesLimit: null,
    sundayMinutesLimit: null,
  });
}
