import { useSet } from "@react-hookz/web";
import { useLiveQuery } from "dexie-react-hooks";

import { getOutlineTasksWithPlans } from "entities/tasks";
import { sortByPriorityAndGroup } from "entities/tasks/model/sort";
import { TaskModel } from "entities/tasks/model/task-model";

import { mergePriorityTask as mergePriorityTaskMutator } from "./mutators";
import { usePriorityGroups } from "./use-priority-groups";

export function usePriorityTasks() {
  const forcefullySplitTasks = useSet<string>();

  const priorityGroups = usePriorityGroups();

  const tasks = useLiveQuery(() => getOutlineTasksWithPlans());

  const prioritizedTasks = tasks
    ?.filter((t) => t.task.priorityInGroup !== undefined)
    ?.sort((a, b) => sortByPriorityAndGroup(a, b, priorityGroups ?? []));

  const expandedPrioritizedTasks = (
    prioritizedTasks?.flatMap(expandTaskTree) ?? []
  ).concat(
    tasks
      ?.filter((t) => t.parent === null && t.task.priorityInGroup === undefined)
      .flatMap(expandTaskTree) ?? [],
  );

  function expandTaskTree(task: TaskModel): TaskModel[] {
    if (!forcefullySplitTasks.has(task.id) || task.children.length === 0)
      return [task];

    return task.children
      .sort((a, b) => a.order - b.order)
      .filter((c) => c.task.priorityInGroup === undefined)
      .flatMap(expandTaskTree);
  }

  function splitPriorityTask(taskId: string) {
    forcefullySplitTasks.add(taskId);
  }

  async function mergePriorityTask(task: TaskModel) {
    await mergePriorityTaskMutator(task.task);

    if (task.parent) forcefullySplitTasks.delete(task.parent.id);
  }

  return {
    tasks,
    prioritizedTasks,
    expandedPrioritizedTasks,
    forcefullySplitTasks,
    splitPriorityTask,
    mergePriorityTask,
  };
}
