import { motion } from "framer-motion";
import React from "react";
import { HiChevronRight } from "react-icons/hi";

import { TaskModel, collapseTask, expandTask } from "entities/tasks";

import styles from "./task-collapse.module.scss";

interface Props {
  task: TaskModel;
}

export function TaskCollapse({ task }: Props) {
  if (task.isCompletable) return null;

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation();

    if (task.isCollapsed) {
      void expandTask(task.task);
    } else {
      void collapseTask(task.task);
    }
  }

  return (
    <motion.div
      className={styles.collapse}
      initial={false}
      animate={{ rotate: task.isCollapsed ? 0 : 90 }}
      transition={{ duration: 0.1 }}
      onClick={handleClick}
      onDoubleClick={(e) => e.stopPropagation()}
    >
      <HiChevronRight className={styles.icon} />
    </motion.div>
  );
}
