import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  COMMAND_PRIORITY_NORMAL,
  KEY_ENTER_COMMAND,
  LineBreakNode,
} from "lexical";
import { useEffect } from "react";

export function PreventEnterPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerNodeTransform(LineBreakNode, (node: LineBreakNode) => {
        node.remove();
      }),
    [editor],
  );

  useEffect(
    () =>
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        () => true,
        COMMAND_PRIORITY_NORMAL,
      ),
    [editor],
  );

  return null;
}
