import { useLiveQuery } from "dexie-react-hooks";
import { useCallback } from "react";

import { database } from "shared/database";
import { Button } from "shared/ui/button";

import styles from "./suggestions-list.module.scss";

interface Props {
  onShowTaskFromSuggestions: (taskId: string) => void;
}

export function SuggestionsList({ onShowTaskFromSuggestions }: Props) {
  const tasks = useLiveQuery(
    () => database.tasks.orderBy("order").toArray(),
    [],
  );

  const suggestions = useLiveQuery(
    () => database.suggestions.orderBy("taskId").toArray(),
    [],
  );

  const handleDelete = useCallback(async (id: string) => {
    await database.suggestions.delete(id);
  }, []);

  const handleShowInTaskList = useCallback(
    (taskId: string) => {
      onShowTaskFromSuggestions(taskId);
    },
    [onShowTaskFromSuggestions],
  );

  if (!suggestions?.length) {
    return <div className={styles.empty}>Нет предложений</div>;
  }

  return (
    <div className={styles.container}>
      {suggestions.map((suggestion) => (
        <div key={suggestion.id} className={styles.item}>
          <div className={styles.taskName}>
            {tasks?.find((t) => t.id === suggestion.taskId)?.name}
          </div>
          <div className={styles.description}>{suggestion.description}</div>
          <div className={styles.actions}>
            <Button
              variant="secondary"
              onClick={() => handleShowInTaskList(suggestion.taskId)}
            >
              Показать в списке задач
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDelete(suggestion.id)}
            >
              Удалить
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
}
