import { useLiveQuery } from "dexie-react-hooks";
import { FC, useRef, useState } from "react";
import { BsLightbulb } from "react-icons/bs";

import { database } from "shared/database";
import { ControlPanel } from "shared/ui";
import { Popover } from "shared/ui/popover";

import styles from "./suggestions-button.module.scss";

import { SuggestionsList } from "./suggestions-list";

interface Props {
  onShowTaskFromSuggestions: (taskId: string) => void;
}

export function SuggestionsButton({ onShowTaskFromSuggestions }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const suggestionsCount = useLiveQuery(
    () => database.suggestions.count(),
    [],
    0,
  );

  return (
    <div ref={containerRef}>
      <ControlPanel.Button
        icon={BsLightbulb}
        onClick={() => setIsOpen((prev) => !prev)}
        label={`Предложения${suggestionsCount > 0 ? ` (${suggestionsCount})` : ""}`}
        hotkey="Meta+Shift+S"
      />
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        targetElement={containerRef.current}
        className={styles.popover}
      >
        <SuggestionsList
          onShowTaskFromSuggestions={onShowTaskFromSuggestions}
        />
      </Popover>
    </div>
  );
}
