import { DateTime } from "luxon";
import { Valid } from "luxon/src/_util";
import { clamp } from "remeda";

import { Session, TimeMapEntity, ZoneEntity } from "shared/database";

import { Event } from "./event";
import { UnableToScheduleError } from "./unable-to-schedule-error";

interface PlanningContext {
  session: Session;
  taskId: string;
  zoneId?: string;
}

export class Schedule {
  private readonly today = DateTime.now().startOf("day");

  public readonly dayEvents: Map<string, Event[]> = new Map();

  constructor(
    private readonly todayStartMinute: number,
    private readonly timeMap: TimeMapEntity,
    private readonly zones: (ZoneEntity & {
      actualDayMinutes: Map<string, number>;
    })[],
    pinnedEvents: Omit<Event, "isPinned">[],
  ) {
    pinnedEvents.forEach((event) => {
      this.dayEvents.set(
        event.day.toISODate(),
        [
          ...(this.dayEvents.get(event.day.toISODate()) ?? []),
          { ...event, isPinned: true },
        ].sort((a, b) => a.startMinute - b.startMinute),
      );
    });
  }

  public hasScheduledSession(session: Session) {
    return this.getScheduledEvents().some((tb) => tb.sessionId === session.id);
  }

  public getScheduledEvents() {
    return Array.from(this.dayEvents.values()).flat();
  }

  public tryRescheduleSessionLater(
    context: PlanningContext,
    startDay: DateTime,
    endDay: DateTime,
  ): Event | UnableToScheduleError {
    for (const day of Array.from(this.dayEvents.keys())) {
      const eventWithSession = this.dayEvents
        .get(day)
        ?.find((tb) => tb.sessionId === context.session.id);

      if (eventWithSession === undefined) continue;

      this.dayEvents.set(day, [
        ...this.dayEvents
          .get(day)!
          .filter((event) => event !== eventWithSession),
      ]);

      return this.tryReserveLatestEvent(
        context,
        endDay,
        eventWithSession.endMinute - eventWithSession.startMinute,
        startDay,
      );
    }

    return {
      reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate()} - ${endDay?.toISODate()}`,
      taskId: context.taskId,
    };
  }

  public tryRescheduleSessionEarlier(
    context: PlanningContext,
    startDay?: DateTime,
    endDay?: DateTime,
  ): Event | UnableToScheduleError {
    for (const day of Array.from(this.dayEvents.keys())) {
      const eventWithSession = this.dayEvents
        .get(day)
        ?.find((tb) => tb.sessionId === context.session.id);

      if (eventWithSession === undefined) continue;

      this.dayEvents.set(day, [
        ...this.dayEvents
          .get(day)!
          .filter((event) => event !== eventWithSession),
      ]);

      return this.tryReserveEarliestEvent(
        context,
        eventWithSession.endMinute - eventWithSession.startMinute,
        startDay,
        endDay,
      );
    }

    return {
      reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate() ?? "сегодня"} - ${endDay?.toISODate() ?? "следующих 60 дней"}`,
      taskId: context.taskId,
    };
  }

  public tryReserveEarliestEvent(
    context: PlanningContext,
    minutes: number,
    startDay?: DateTime,
    endDay?: DateTime,
  ): Event | UnableToScheduleError {
    const innerErrors: UnableToScheduleError[] = [];

    for (
      let currentDay = startDay ?? this.today;
      currentDay <= (endDay ?? this.today.plus({ day: 60 }));
      currentDay = currentDay.plus({ day: 1 })
    ) {
      const reservedEventOrError = this.tryReserveEarliestEventInDay(
        context,
        currentDay,
        minutes,
      );

      if ("reason" in reservedEventOrError) {
        innerErrors.push(reservedEventOrError);
      } else {
        return reservedEventOrError;
      }
    }

    return {
      reason: `Не найдено свободное время в диапазоне ${startDay?.toISODate() ?? "сегодня"} - ${endDay?.toISODate() ?? "следующих 60 дней"}`,
      taskId: context.taskId,
      innerErrors,
    };
  }

  private tryReserveEarliestEventInDay(
    context: PlanningContext,
    day: DateTime<Valid>,
    minutes: number,
  ): Event | UnableToScheduleError {
    if (context.zoneId && !this.isTaskFitsZoneLimit(context, day, minutes)) {
      return {
        reason: `Задаче не хватило времени в рамках дня ${day.toISODate()} в зоне ${this.zones.find((z) => z.id === context.zoneId)?.name}`,
        taskId: context.taskId,
      };
    }

    const dayEvents = this.dayEvents.get(day.toISODate()) ?? [];
    const reservedMinutePoints = [
      this.getDayStartMinute(day),
      ...dayEvents
        .filter(
          (e) =>
            !e.isPinned ||
            (e.endMinute >= this.getDayStartMinute(day) &&
              e.startMinute <= this.getDayEndMinute(day)),
        )
        .sort((a, b) => a.startMinute - b.startMinute)
        .flatMap((tb) => [tb.startMinute, tb.endMinute]),
      this.getDayEndMinute(day),
    ];

    for (
      let minutePointPairIndex = 0;
      minutePointPairIndex < reservedMinutePoints.length;
      minutePointPairIndex += 2
    ) {
      const [startingFreeMinute, endingFreeMinute] = [
        reservedMinutePoints[minutePointPairIndex],
        reservedMinutePoints[minutePointPairIndex + 1],
      ];

      if (endingFreeMinute - startingFreeMinute >= minutes) {
        const event: Event = {
          day,
          startMinute: startingFreeMinute,
          endMinute: startingFreeMinute + minutes,
          sessionId: context.session.id,
          taskId: context.taskId,
          zoneId: context.zoneId,
          isPinned: false,
        };

        const dayEvents = this.dayEvents.get(day.toISODate());
        if (!dayEvents) this.dayEvents.set(day.toISODate(), [event]);
        else this.dayEvents.set(day.toISODate(), [...dayEvents, event]);

        return event;
      }
    }

    return {
      reason: `Не найдено свободное время в рамках дня ${day.toISODate()}`,
      taskId: context.taskId,
    };
  }

  public tryReserveLatestEvent(
    context: PlanningContext,
    endDay: DateTime,
    minutes: number,
    startDay?: DateTime,
  ): Event | UnableToScheduleError {
    if (startDay && endDay < startDay)
      throw new Error(
        `End day ${endDay} is earlier than start day ${startDay}`,
      );

    if (minutes < 1) throw new Error(`Minutes are less than 1: ${minutes}`);

    const effectiveStartDay = startDay ?? this.today;
    const innerErrors: UnableToScheduleError[] = [];

    for (
      let currentDay = endDay;
      currentDay >= effectiveStartDay;
      currentDay = currentDay.minus({ day: 1 })
    ) {
      const reservedEventOrError = this.tryReserveLatestEventInDay(
        context,
        currentDay,
        minutes,
      );

      if ("reason" in reservedEventOrError) {
        innerErrors.push(reservedEventOrError);
      } else {
        return reservedEventOrError;
      }
    }

    return {
      reason: `Не найдено время в диапазоне ${effectiveStartDay.toISODate()} - ${endDay.toISODate()}`,
      taskId: context.taskId,
      innerErrors,
    };
  }

  private tryReserveLatestEventInDay(
    context: PlanningContext,
    day: DateTime<Valid>,
    minutes: number,
  ): Event | UnableToScheduleError {
    if (context.zoneId && !this.isTaskFitsZoneLimit(context, day, minutes)) {
      return {
        reason: `Задаче не хватило времени в рамках дня ${day.toISODate()} в зоне ${this.zones.find((z) => z.id === context.zoneId)?.name}`,
        taskId: context.taskId,
      };
    }

    const dayEvents = this.dayEvents.get(day.toISODate()) ?? [];
    const reservedMinutePoints = [
      this.getDayEndMinute(day),
      ...dayEvents
        .filter(
          (e) =>
            !e.isPinned ||
            (e.endMinute >= this.getDayStartMinute(day) &&
              e.startMinute <= this.getDayEndMinute(day)),
        )
        .sort((a, b) => b.endMinute - a.endMinute)
        .flatMap((tb) => [tb.endMinute, tb.startMinute]),
      this.getDayStartMinute(day),
    ];

    for (
      let minutePointPairIndex = 0;
      minutePointPairIndex < reservedMinutePoints.length;
      minutePointPairIndex += 2
    ) {
      const [endingFreeMinute, startingFreeMinute] = [
        reservedMinutePoints[minutePointPairIndex],
        reservedMinutePoints[minutePointPairIndex + 1],
      ];

      if (endingFreeMinute - startingFreeMinute >= minutes) {
        const event: Event = {
          day,
          startMinute: endingFreeMinute - minutes,
          endMinute: endingFreeMinute,
          sessionId: context.session.id,
          taskId: context.taskId,
          zoneId: context.zoneId,
          isPinned: false,
        };

        const dayEvents = this.dayEvents.get(day.toISODate());
        if (!dayEvents) this.dayEvents.set(day.toISODate(), [event]);
        else this.dayEvents.set(day.toISODate(), [...dayEvents, event]);

        return event;
      }
    }

    return {
      reason: `Не найдено свободное время в рамках дня ${day.toISODate()}`,
      taskId: context.taskId,
    };
  }

  private getDayStartMinute(day: DateTime) {
    if (day.equals(DateTime.now().startOf("day"))) {
      return clamp(this.todayStartMinute, {
        min: this.getWeekdayTimeMap(day).startTimeSlot * 15,
        max: this.getWeekdayTimeMap(day).endTimeSlot * 15,
      });
    }
    return this.getWeekdayTimeMap(day).startTimeSlot * 15;
  }

  private getDayEndMinute(day: DateTime) {
    return this.getWeekdayTimeMap(day).endTimeSlot * 15;
  }

  private getWeekdayTimeMap(day: DateTime) {
    return this.timeMap[this.getWeekdayName(day)];
  }

  private getWeekdayZoneMinutesLimit(day: DateTime, zone: ZoneEntity) {
    return zone[`${this.getWeekdayName(day)}MinutesLimit`];
  }

  private getWeekdayName(day: DateTime) {
    const weekDay = day.weekday;
    switch (weekDay) {
      case 1:
        return "monday";
      case 2:
        return "tuesday";
      case 3:
        return "wednesday";
      case 4:
        return "thursday";
      case 5:
        return "friday";
      case 6:
        return "saturday";
      case 7:
        return "sunday";
      default:
        throw new Error(`Invalid weekday: ${weekDay}`);
    }
  }

  private isTaskFitsZoneLimit(
    context: PlanningContext,
    day: DateTime,
    duration: number,
  ) {
    const zone = this.zones.find((z) => z.id === context.zoneId);
    if (!zone) throw new Error(`Can't find zone with id ${context.zoneId}`);

    const reservedDayMinutes = this.calculateDayZoneMinutes(day, zone.id);

    const weekdayMinutesLimit = this.getWeekdayZoneMinutesLimit(day, zone);
    if (
      weekdayMinutesLimit &&
      reservedDayMinutes + duration > weekdayMinutesLimit
    ) {
      return false;
    }

    const dayMinutesLimit = zone.dayMinutesLimit;
    if (dayMinutesLimit && reservedDayMinutes + duration > dayMinutesLimit) {
      return false;
    }

    const weekMinutesLimit = zone.weekMinutesLimit;
    if (weekMinutesLimit) {
      const weekStart = day.startOf("week");
      const weekEnd = day.endOf("week");

      let reservedWeekMinutes = 0;
      for (
        let currentDay = weekStart;
        currentDay <= weekEnd;
        currentDay = currentDay.plus({ days: 1 })
      ) {
        reservedWeekMinutes += this.calculateDayZoneMinutes(
          currentDay,
          zone.id,
        );
      }

      if (reservedWeekMinutes + duration > weekMinutesLimit) {
        return false;
      }
    }

    return true;
  }

  private calculateDayZoneMinutes(day: DateTime<Valid>, zoneId: string) {
    const zoneActualMinutes =
      this.zones
        .find((z) => z.id === zoneId)
        ?.actualDayMinutes.get(day.toISODate()) ?? 0;

    const plannedEventsMinutes =
      this.dayEvents
        .get(day.toISODate())
        ?.filter((e) => e.zoneId === zoneId)
        ?.reduce((p, e) => p + (e.endMinute - e.startMinute), 0) ?? 0;

    return plannedEventsMinutes + zoneActualMinutes;
  }
}
