import { flatMap, groupBy, map, pipe, values } from "remeda";
import { v4 as uuidv4 } from "uuid";

import { TaskModel, getOutlineTasksQuery, updateTask } from "entities/tasks";

import { BaseTaskEntity, database } from "shared/database";

export async function createPriorityGroup() {
  await database.transaction(
    "readwrite",
    [database.priorityGroups],
    async () => {
      const latestPriorityGroup = await database.priorityGroups
        .orderBy("order")
        .reverse()
        .first();

      const groupOrder = Math.max(latestPriorityGroup?.order ?? 0, 0) + 1;

      await database.priorityGroups.add({
        id: uuidv4(),
        name: `Группа ${groupOrder}`,
        order: groupOrder,
      });
    },
  );
}

export async function renamePriorityGroup(groupId: string, name: string) {
  await database.priorityGroups.update(groupId, { name });
}

export async function deletePriorityGroup(groupId: string) {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      await database.tasks
        .where("priorityGroupId")
        .equals(groupId)
        .modify({ priorityGroupId: null, priorityInGroup: undefined });

      await database.priorityGroups.delete(groupId);
    },
  );
}

export async function mergePriorityTask(task: BaseTaskEntity) {
  if (task.priorityInGroup !== undefined) {
    await updateTask(task, {
      priorityGroupId: null,
      priorityInGroup: undefined,
    });
    await resetTasksPriorities();
  }
}

export async function prioritizeTask(
  task: TaskModel,
  target: TaskModel,
  position: "before" | "after",
) {
  function findClosestParentPriority(task: TaskModel) {
    if (task.task.priorityInGroup !== undefined)
      return task.task.priorityInGroup;
    if (!task.parent) return null;
    return findClosestParentPriority(task.parent);
  }

  const prioritizedTasksCount = await getOutlineTasksQuery()
    .filter((t) => t.priorityInGroup !== undefined)
    .count();

  const targetPriority =
    findClosestParentPriority(target) ?? (prioritizedTasksCount + 1) * 10000;

  await updateTask(task.task, {
    priorityGroupId: target.task.priorityGroupId,
    priorityInGroup:
      position === "before" ? targetPriority - 5000 : targetPriority + 5000,
  });

  await resetTasksPriorities();
}

export async function changeTaskPriorityGroup(
  task: BaseTaskEntity,
  groupId: string | null,
) {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      const tasksInGroup = await getOutlineTasksQuery()
        .filter((t) => t.priorityGroupId === groupId)
        .toArray();

      await updateTask(task, {
        priorityGroupId: groupId,
        priorityInGroup: (tasksInGroup.length + 1) * 10000,
      });

      await resetTasksPriorities();
    },
  );
}

export async function resetTasksPriorities() {
  await database.transaction(
    "readwrite",
    [database.tasks, database.priorityGroups],
    async () => {
      const tasksWithPriority = await getOutlineTasksQuery()
        .filter((t) => t.priorityInGroup !== undefined)
        .sortBy("priorityInGroup");

      await database.tasks.bulkUpdate(
        pipe(
          tasksWithPriority,
          groupBy((t) => t.priorityGroupId ?? "default"),
          values(),
          flatMap(
            map((task, index) => ({
              key: task.id,
              changes: {
                priorityInGroup: (index + 1) * 10000,
              },
            })),
          ),
        ),
      );
    },
  );
}
