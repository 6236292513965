import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useRegisterSW } from "virtual:pwa-register/react";

import { routes } from "app/routing";

import { useUpdateChecker } from "shared/libs/pwa";

import "./app.scss";

export function App() {
  useRegisterSW({ immediate: true });
  useUpdateChecker();

  return <RouterProvider router={createBrowserRouter(routes)} />;
}
