import { AnimatePresence, motion } from "framer-motion";
import React, { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import {
  BsClockHistory,
  BsFillPieChartFill,
  BsListNested,
  BsListTask,
  BsSortUp,
} from "react-icons/bs";
import { LiaUtensilsSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";

import { Calendar } from "features/calendar";
import { Outline } from "features/outline";
import { Priorities } from "features/priorities";
import { ScheduleButton } from "features/scheduler";
import { SuggestionsButton } from "features/suggestions";
import { TimeMaps } from "features/time-maps";
import { Zones } from "features/zones";

import {
  ExportButton,
  ImportButton,
  QuickAddButton,
  logCompletedTasks,
} from "entities/tasks";

import { routePaths } from "shared/router";
import { ControlPanel, Layout } from "shared/ui";

import styles from "./tasks-page.module.scss";

export enum PanelType {
  OUTLINE = "outline",
  PRIORITIES = "priorities",
  ZONES = "zones",
  TIME_MAPS = "time-maps",
}

export function TasksPage() {
  const navigate = useNavigate();

  const [activePanel, setActivePanel] = useState<PanelType>(PanelType.OUTLINE);
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();

  const currentPanel = useMemo(() => {
    switch (activePanel) {
      case PanelType.PRIORITIES:
        return {
          title: "Приоритеты",
          component: <Priorities />,
        };
      case PanelType.ZONES:
        return {
          title: "Зоны",
          component: <Zones />,
        };
      case PanelType.TIME_MAPS:
        return {
          title: "Временные карты",
          component: <TimeMaps />,
        };
      case PanelType.OUTLINE:
      default:
        return {
          title: "План",
          component: (
            <Outline
              taskIdToSelect={selectedTaskId}
              onTaskSelected={() => setSelectedTaskId(undefined)}
            />
          ),
        };
    }
  }, [activePanel, selectedTaskId]);

  useHotkeys("Meta+Shift+Y", async () => {
    await logCompletedTasks();
  });

  return (
    <div>
      <Layout>
        <Calendar
          onShowTaskInOutline={(taskId) => {
            setSelectedTaskId(taskId);
            setActivePanel(PanelType.OUTLINE);
          }}
        />
        <div className={styles.panel}>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentPanel.title}
              className={styles.header}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.1 }}
            >
              {currentPanel.title}
            </motion.div>
          </AnimatePresence>
          {currentPanel.component}
        </div>
      </Layout>
      <ControlPanel>
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.OUTLINE)}
          icon={BsListNested}
          label="План"
          hotkey="Meta+1"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.PRIORITIES)}
          icon={BsSortUp}
          label="Приоритеты"
          hotkey="Meta+2"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.ZONES)}
          icon={BsFillPieChartFill}
          label="Зоны"
          hotkey="Meta+3"
        />
        <ControlPanel.Button
          onClick={() => setActivePanel(PanelType.TIME_MAPS)}
          icon={BsClockHistory}
          label="Временные карты"
          hotkey="Meta+4"
        />
        <ScheduleButton />
        <QuickAddButton />
        <ControlPanel.Divider />
        <SuggestionsButton
          onShowTaskFromSuggestions={(taskId) => {
            setSelectedTaskId(taskId);
            setActivePanel(PanelType.OUTLINE);
          }}
        />
        <ControlPanel.Divider />
        <ExportButton />
        <ImportButton />
        <ControlPanel.Divider />
        <ControlPanel.Button
          icon={BsListTask}
          onClick={() => navigate(routePaths.TASKS)}
          label="Задачи"
        />
        <ControlPanel.Button
          icon={LiaUtensilsSolid}
          onClick={() => navigate(routePaths.MEALS)}
          hotkey="Meta+M"
          label="Питание"
        />
      </ControlPanel>
    </div>
  );
}
