import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useLexicalTextEntity } from "@lexical/react/useLexicalTextEntity";
import {
  $createTextNode,
  EditorConfig,
  LexicalEditor,
  SerializedTextNode,
  TextNode,
} from "lexical";
import { useCallback, useEffect } from "react";

export class DurationNode extends TextNode {
  static getType() {
    return "duration";
  }

  static clone(node: DurationNode) {
    return new DurationNode(node.__text, node.__key);
  }

  static importJSON(serializedNode: SerializedTextNode) {
    const node = new DurationNode(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(text: string, key?: string) {
    super(text, key);
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const element = super.createDOM(config, editor);
    element.style.backgroundColor = "var(--color-purple-light)";
    element.style.color = "var(--color-background)";
    element.style.padding = "0 5px";
    element.style.borderRadius = "8px";
    return element;
  }

  exportJSON(): SerializedTextNode {
    return { ...super.exportJSON(), type: "duration" };
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  isTextEntity(): true {
    return true;
  }
}

interface DurationsPluginProps {
  fullTimeRegexp: RegExp;
  hoursRegexp: RegExp;
  minutesRegexp: RegExp;
}

export function DurationsPlugin({
  fullTimeRegexp,
  hoursRegexp,
  minutesRegexp,
}: DurationsPluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    function ensureNodeIsRegistered() {
      if (!editor.hasNode(DurationNode))
        throw new Error(`Node ${typeof DurationNode} is not registered`);
    },
    [editor],
  );

  const getMatch = useCallback(
    (text: string) => {
      const match =
        fullTimeRegexp.exec(text) ??
        minutesRegexp.exec(text) ??
        hoursRegexp.exec(text);

      if (!match) return null;

      return { start: match.index, end: match.index + match[0].length };
    },
    [fullTimeRegexp, hoursRegexp, minutesRegexp],
  );

  const createDurationNode = useCallback((textNode: TextNode) => {
    return new DurationNode(textNode.getTextContent());
  }, []);

  useLexicalTextEntity<DurationNode>(
    getMatch,
    DurationNode,
    createDurationNode,
  );

  return null;
}
