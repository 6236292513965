import React from "react";
import { IoIosRefresh } from "react-icons/io";

import { useScheduler } from "features/scheduler";

import { ControlPanel } from "shared/ui/control-panel";

export function ScheduleButton() {
  const { schedule } = useScheduler();

  return (
    <ControlPanel.Button
      icon={IoIosRefresh}
      onClick={schedule}
      hotkey="Meta+S"
      label="Пересчитать расписание"
    />
  );
}
