import { DateTime } from "luxon";
import { useCallback } from "react";
import { entries, groupBy, mapValues, pipe, sum } from "remeda";
import { v4 } from "uuid";

import { Scheduler } from "features/scheduler/model/scheduler";

import { clearEventsWithoutSessions, useEvents } from "entities/events";
import {
  getAllTasks,
  getOutlineTasks,
  scheduleTaskSessions,
} from "entities/tasks";
import { sortByPriorityAndGroup } from "entities/tasks/model/sort";
import { TaskModel } from "entities/tasks/model/task-model";
import { useZones } from "entities/zones/model/use-zones";

import { database } from "shared/database";
import { roundMinutesToCeilingSlot } from "shared/libs/time";

import { stringifyUnableToScheduleError } from "./unable-to-schedule-error";

export function useScheduler() {
  const { replace } = useEvents();

  const { zones } = useZones();

  const schedule = useCallback(async () => {
    await database.transaction(
      "readwrite",
      [
        database.tasks,
        database.events,
        database.timeMaps,
        database.suggestions,
        database.priorityGroups,
      ],
      async () => {
        const tasksToUpdateSessions = await getOutlineTasks();
        await Promise.all(
          tasksToUpdateSessions.map((t) => scheduleTaskSessions(t.task)),
        );

        await clearEventsWithoutSessions();

        const allTasks = await getAllTasks();
        const schedulingTasks = allTasks.filter(
          (t) => t.task.location === "outline",
        );

        const defaultTimeMap = (
          await database.timeMaps.filter((t) => t.isDefault).toArray()
        )[0];

        if (!defaultTimeMap) {
          console.error("No default time map found");
          return;
        }

        const today = DateTime.now().startOf("day");
        const currentSlot = Math.round(
          Math.abs(today.diffNow().as("minutes") / 15),
        );

        const priorityGroups = await database.priorityGroups.toArray();

        const prioritizedTasks = schedulingTasks
          .filter((t) => t.task.priorityInGroup !== undefined && !t.isPaused)
          .sort((a, b) => sortByPriorityAndGroup(a, b, priorityGroups))
          .flatMap(expandTaskTree);

        const unprioritizedTasks = schedulingTasks
          .filter(
            (t) =>
              t.parent === null &&
              t.task.priorityInGroup === undefined &&
              !t.isPaused,
          )
          .flatMap(expandTaskTree);

        const taskQueue = prioritizedTasks.concat(unprioritizedTasks);

        const sessions = taskQueue
          .flatMap((t) => t.uncompletedSessions)
          .filter((session) => !session.skipped);

        const events = await database.events.toArray();

        const scheduler = new Scheduler(
          sessions,
          zones.map((z) => {
            const zoneTaskIds = new Set(
              allTasks.filter((t) => t.zoneId === z.id).map((t) => t.id),
            );

            return {
              ...z,
              actualDayMinutes: new Map<string, number>(
                pipe(
                  events.filter(
                    (e) => e.actualDuration && zoneTaskIds.has(e.taskId),
                  ),
                  groupBy((e) => DateTime.fromJSDate(e.startDate).toISODate()!),
                  mapValues((g) => sum(g.map((e) => e.actualDuration!))),
                  entries(),
                ),
              ),
            };
          }),
          events.filter(
            (e) =>
              e.isPinned &&
              !e.actualDuration &&
              DateTime.fromJSDate(e.startDate).plus({
                minutes: roundMinutesToCeilingSlot(e.duration),
              }) > DateTime.now(),
          ),
        );

        const { events: scheduledEvents, errors } = scheduler.schedule(
          currentSlot,
          defaultTimeMap,
        );

        await database.suggestions.clear();
        await database.suggestions.bulkAdd(
          errors.map((e) => ({
            id: v4(),
            reason: e.reason,
            taskId: e.taskId,
            description: stringifyUnableToScheduleError(e),
            creationDateTimeUtc: DateTime.now().toJSDate(),
          })),
        );

        await replace(
          scheduledEvents.map((e) => ({
            taskId: e.taskId,
            sessionId: e.sessionId,
            day: e.day,
            startMinute: e?.startMinute,
            endMinute: e?.endMinute,
            isPinned: e?.isPinned,
          })),
        );
      },
    );
  }, [replace, zones]);

  return { schedule };
}

function expandTaskTree(task: TaskModel): TaskModel[] {
  if (task.children.length === 0) return [task];

  return task.children
    .filter((c) => c.task.priorityInGroup === undefined && !c.isPaused)
    .sort((a, b) => a.order - b.order)
    .flatMap(expandTaskTree);
}
