import ls from "localstorage-slim";
import { useCallback, useEffect, useState } from "react";

const STORAGE_KEY = "priorities:collapsed-groups";

export function useGroupCollapse(groupId: string | null) {
  const groupIdKey = groupId ?? "default";

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const collapsedGroups = ls.get<string[]>(STORAGE_KEY) ?? [];
    return collapsedGroups.includes(groupIdKey);
  });

  useEffect(() => {
    const collapsedGroups = ls.get<string[]>(STORAGE_KEY) ?? [];

    if (isCollapsed && !collapsedGroups.includes(groupIdKey)) {
      ls.set(STORAGE_KEY, [...collapsedGroups, groupIdKey]);
    } else if (!isCollapsed && collapsedGroups.includes(groupIdKey)) {
      ls.set(
        STORAGE_KEY,
        collapsedGroups.filter((id) => id !== groupIdKey),
      );
    }
  }, [groupIdKey, isCollapsed]);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  return {
    isCollapsed,
    toggleCollapse,
  };
}
