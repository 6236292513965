import { forwardRef } from "react";

import styles from "./text-input.module.scss";

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ autoFocus, label, value, onChange, onBlur, onKeyDown }, ref) => {
    return (
      <div className={styles.input}>
        <input
          ref={ref}
          className={styles.control}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          autoFocus={autoFocus}
          placeholder=" "
          onKeyDown={onKeyDown}
        />
        <label className={styles.label}>{label}</label>
      </div>
    );
  },
);
