import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { TextInput } from "shared/ui/text-input";

import styles from "./task-search.module.scss";

interface Props {
  value: string;
  onValueChange: (query: string) => void;
}

export function TaskSearch({
  value: searchQuery,
  onValueChange: onSearchQueryChange,
}: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useHotkeys(
    "Meta+F",
    () => {
      setIsVisible(true);
      queueMicrotask(() => {
        searchInputRef.current?.focus();
      });
    },
    {
      preventDefault: true,
    },
  );

  function handleBlur() {
    if (searchQuery === "") setIsVisible(false);
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    event.stopPropagation();

    if (event.key === "Escape") {
      setIsVisible(false);
      onSearchQueryChange("");
    }
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className={styles.search}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.1 } }}
          transition={{ duration: 0.3 }}
        >
          <TextInput
            ref={searchInputRef}
            label="Поиск задач"
            value={searchQuery}
            onChange={onSearchQueryChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
