import {
  Edge,
  attachClosestEdge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { RefObject, useEffect, useState } from "react";

import { TaskModel } from "entities/tasks/model/task-model";

interface UseTaskDragProps {
  ref: RefObject<HTMLDivElement>;
  task: TaskModel;
  onPrioritize: (target: TaskModel, position: "before" | "after") => void;
  onGroupChange?: (groupId: string | null) => void;
}

export function useTaskDrag({
  ref,
  task,
  onPrioritize,
  onGroupChange,
}: UseTaskDragProps) {
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    if (!ref.current) return;

    return combine(
      draggable({
        element: ref.current,
        getInitialData: () => ({
          type: "task",
          height: ref.current!.getBoundingClientRect().height ?? 0,
          task,
          groupId: task.task.priorityGroupId,
        }),
        onDrop: ({ location }) => {
          const destination = location.current.dropTargets[0];

          if (destination.data.type === "task") {
            onPrioritize?.(
              destination.data.task as TaskModel,
              extractClosestEdge(destination.data) === "top"
                ? "before"
                : "after",
            );
          } else if (destination.data.type === "group") {
            onGroupChange?.(destination.data.groupId as string | null);
          }
        },
      }),
      dropTargetForElements({
        element: ref.current,
        canDrop: ({ source }) => source.data.type === "task",
        getIsSticky: () => true,
        getData: ({ input, element }) =>
          attachClosestEdge(
            { type: "task", task },
            {
              input,
              element,
              allowedEdges: ["top", "bottom"],
            },
          ),
        onDrag: ({ self }) => {
          setClosestEdge(extractClosestEdge(self.data));
        },
        onDragEnter: ({ self }) => {
          setClosestEdge(extractClosestEdge(self.data));
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: () => setClosestEdge(null),
      }),
    );
  }, [setClosestEdge, task, onPrioritize, onGroupChange]);

  return { closestEdge };
}
