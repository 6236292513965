import React, { useState } from "react";
import { BsPlusLg } from "react-icons/bs";

import { ControlPanel } from "shared/ui";

import { QuickAddModal } from "./quick-add-modal";

export function QuickAddButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ControlPanel.Button
        onClick={() => setIsModalOpen(true)}
        icon={BsPlusLg}
        hotkey="Meta+N"
        label="Создать задачу"
      />
      <QuickAddModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
