import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from "lexical";

export function PreventEnterPropagationPlugin() {
  const [editor] = useLexicalComposerContext();

  editor.registerCommand(
    KEY_ENTER_COMMAND,
    (e) => {
      if (e !== null) {
        if (e.metaKey) return true;
        else e.stopPropagation();
      }
      return false;
    },
    COMMAND_PRIORITY_CRITICAL,
  );

  return null;
}
