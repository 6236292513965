import { PriorityGroupEntity } from "shared/database";

import { TaskModel } from "./task-model";

export function sortByPriorityAndGroup(
  a: TaskModel,
  b: TaskModel,
  priorityGroups: PriorityGroupEntity[],
): number {
  const groupOrderA =
    priorityGroups.find((g) => g.id === a.task.priorityGroupId)?.order ?? 0;
  const groupOrderB =
    priorityGroups.find((g) => g.id === b.task.priorityGroupId)?.order ?? 0;

  if (groupOrderA !== groupOrderB) return groupOrderA - groupOrderB;

  return a.task.priorityInGroup! - b.task.priorityInGroup!;
}
