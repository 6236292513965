import { clsx } from "clsx";
import { motion } from "framer-motion";
import { ReactNode, forwardRef } from "react";
import { range } from "remeda";

import { TaskModel } from "entities/tasks/model/task-model";

import styles from "./task.module.scss";

interface Props {
  task: TaskModel;
  level: number;
  isSelected: boolean;
  children: ReactNode;
  className?: string;
}

export const Task = forwardRef<HTMLDivElement, Props>(
  ({ task, level, isSelected, children, className }: Props, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(styles.task, className)}
        style={{
          gridTemplateColumns: `repeat(${level}, 28px) 1fr`,
        }}
      >
        {range(0, level).map((i) => (
          <div key={i} className={styles.verticalLine} />
        ))}
        <motion.div
          layout="position"
          layoutId={`task-${task.id}`}
          style={{
            zIndex: isSelected ? 1 : 0,
            opacity: task.isPaused ? 0.6 : 1,
          }}
        >
          {children}
        </motion.div>
      </div>
    );
  },
);

Task.displayName = "Task";
