import { useLiveQuery } from "dexie-react-hooks";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TaskModel, getOutlineTasks } from "entities/tasks";

import styles from "./outline.module.scss";

import { TaskSearch } from "./task-search";
import { TaskTree } from "./task-tree";

function getBreadcrumbs(task: TaskModel | null | undefined): TaskModel[] {
  if (!task) return [];
  const breadcrumbs = getBreadcrumbs(task.parent);
  return [...breadcrumbs, task];
}

interface OutlineProps {
  taskIdToSelect?: string;
  onTaskSelected?: () => void;
}

export function Outline({ taskIdToSelect, onTaskSelected }: OutlineProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const zoomTaskId = searchParams.get("zoomTaskId");

  const [searchQuery, setSearchQuery] = useState("");

  const tasks = useLiveQuery(() => getOutlineTasks(), []);
  const roots = tasks?.filter((root) => {
    if (searchQuery && ![root, ...root.descendants].some(hasSearchQuery))
      return false;

    if (zoomTaskId !== null) return root.task.parentId === zoomTaskId;

    return root.task.parentId === null;

    function hasSearchQuery(task: TaskModel) {
      return (
        task.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        task.task.notes?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  });

  const zoomedTask = tasks?.find((t) => t.task.id === zoomTaskId);
  const breadcrumbs = getBreadcrumbs(zoomedTask);

  if (!tasks || !roots) return null;

  return (
    <div className={styles.outline}>
      <div className={styles.header}>
        {breadcrumbs.length > 0 && (
          <div className={styles.breadcrumbs}>
            <span className={styles.crumb} onClick={() => setSearchParams({})}>
              🏠
            </span>
            {breadcrumbs.map((task, index) => (
              <React.Fragment key={task.id}>
                <span className={styles.separator}>/</span>
                <span
                  className={styles.crumb}
                  onClick={() =>
                    index === breadcrumbs.length - 1
                      ? undefined
                      : setSearchParams({ zoomTaskId: task.id.toString() })
                  }
                >
                  {task.title}
                </span>
              </React.Fragment>
            ))}
          </div>
        )}
        <TaskSearch value={searchQuery} onValueChange={setSearchQuery} />
      </div>
      <TaskTree
        key={`${zoomTaskId}-${searchQuery}`}
        tasks={tasks}
        roots={roots}
        taskIdToSelect={taskIdToSelect}
        onTaskSelected={onTaskSelected}
      />
    </div>
  );
}
