import { motion } from "framer-motion";
import React, { useState } from "react";
import { createPortal } from "react-dom";

import { Button } from "shared/ui/button";
import { TextInput } from "shared/ui/text-input";

import styles from "./quick-add-modal.module.scss";

import { createTask } from "../model";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export function QuickAddModal({ isOpen, onClose }: Props) {
  const [taskName, setTaskName] = useState("");

  const handleSubmit = async () => {
    if (!taskName.trim()) return;

    await createTask(taskName.trim(), null, 0);
    setTaskName("");
    onClose();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      onClose();
    } else if (e.key === "Enter") {
      void handleSubmit();
      e.stopPropagation();
    } else if (e.key === "Tab") {
      e.preventDefault();
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div className={styles.overlay}>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className={styles.modal}
        onKeyDown={handleKeyDown}
      >
        <TextInput
          label="Название задачи"
          value={taskName}
          onChange={setTaskName}
          autoFocus
        />
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            Отменить
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Создать задачу
          </Button>
        </div>
      </motion.div>
    </div>,
    document.body,
  );
}
