import { clsx } from "clsx";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { range } from "remeda";

import styles from "./week-days.module.scss";

interface WeekDaysProps {
  startDay: DateTime;
  days: number;
}

export function WeekDays({ startDay, days }: WeekDaysProps) {
  const [currentDay, setCurrentDay] = useState(DateTime.now().startOf("day"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (DateTime.now().startOf("day").equals(currentDay)) return;

      setCurrentDay(DateTime.now().startOf("day"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={styles.weekDays}>
      {range(0, days).map((dayIndex) => {
        const day = startDay.plus({ day: dayIndex });

        return (
          <div
            key={day.toISO()}
            className={clsx(styles.day, {
              [styles.isCurrent]: currentDay.equals(day),
            })}
          >
            <span className={styles.weekday}>
              {day.toLocaleString({ weekday: "short" }, { locale: "ru" })}
            </span>
            <span className={styles.date}>
              {day.toLocaleString({ day: "2-digit" }, { locale: "ru" })}
            </span>
          </div>
        );
      })}
    </div>
  );
}
