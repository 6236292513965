export interface UnableToScheduleError {
  reason: string;
  taskId: string;
  innerErrors?: UnableToScheduleError[];
}

export function stringifyUnableToScheduleError(
  error: UnableToScheduleError,
  level = 0,
): string {
  const indent = " ".repeat(level * 2);

  return [
    error.reason + (error.innerErrors ? ":" : ""),
    ...(error.innerErrors ?? [])
      .sort((a, b) => a.taskId.localeCompare(b.taskId))
      .map((e) => indent + "- " + stringifyUnableToScheduleError(e, level + 1)),
  ].join("\n");
}
