import { DateTime } from "luxon";
import invariant from "tiny-invariant";

import { RecurringPlan, TaskEntity } from "shared/database";

export function isRecurringTask(
  task: TaskEntity,
): task is TaskEntity & { plan: RecurringPlan } {
  return task.plan?.type === "recurring";
}

export class TaskModel {
  constructor(
    public task: TaskEntity,
    private taskChildren: Map<string | null, TaskEntity[]>,
  ) {}

  public get id() {
    return this.task.id;
  }

  public get title() {
    return this.task.name;
  }

  public get parent(): TaskModel | null {
    if (this.task.parentId === null) return null;

    const parent = Array.from(this.taskChildren.values())
      .flat()
      .find((t) => t.id === this.task.parentId);

    invariant(parent);

    return new TaskModel(parent, this.taskChildren);
  }

  public get children(): TaskModel[] {
    return (
      this.taskChildren
        .get(this.task.id)
        ?.map((t) => new TaskModel(t, this.taskChildren))
        .sort((a, b) => a.order - b.order) ?? []
    );
  }

  public get descendants(): TaskModel[] {
    return [...this.children, ...this.children.flatMap((c) => c.descendants)];
  }

  public get siblings(): TaskModel[] {
    return (
      this.taskChildren
        .get(this.task.parentId)
        ?.map((t) => new TaskModel(t, this.taskChildren)) ?? []
    );
  }

  public get zoneId(): string | undefined {
    return this.task.zoneId ? this.task.zoneId : this.parent?.zoneId;
  }

  public get order(): number {
    return this.task.order;
  }

  public get isCompleted() {
    return (
      this.getIsCompletedForDateTime(DateTime.now()) &&
      this.allChildrenCompleted
    );
  }

  public get isPaused() {
    return this.task.pausedAt !== undefined;
  }

  /**
   * Checks if all child tasks are completed
   * @returns {boolean} True if all children are completed or if there are no children
   */
  public get allChildrenCompleted(): boolean {
    return this.children.every((child) => child.isCompleted);
  }

  /**
   * Whether the task can be completed
   * @returns {boolean} True if the task has no children or all children are completed
   */
  public get isCompletable(): boolean {
    return !this.isParent || this.allChildrenCompleted;
  }

  public getIsCompletedForDateTime(dateTime: DateTime) {
    switch (this.task.plan?.type) {
      case undefined:
        return this.task.completedAt !== undefined;
      case "flexible":
        invariant(this.task.plan.sessions.length === 1);
        return this.task.plan.sessions[0].completedAt !== undefined;
      case "recurring":
        return (
          this.task.plan.sessions.find(
            (s) =>
              DateTime.fromISO(s.startDate) <= dateTime &&
              dateTime < DateTime.fromISO(s.startDate).plus({ days: s.days }),
          )?.completedAt !== undefined
        );
      default:
        throw new Error("Unknown plan type");
    }
  }

  public get isCollapsed() {
    return this.task.collapsed;
  }

  public get isParent() {
    return this.children.length !== 0;
  }

  public get duration(): number {
    return (
      this.children.reduce((prev, curr) => prev + curr.duration, 0) +
      (this.isParent ? 0 : this.task.duration)
    );
  }

  public get uncompletedSessions() {
    return (
      this.task.plan?.sessions
        .filter((s) => !s.completedAt)
        .map((s) => ({
          task: this,
          ...s,
        })) ?? []
    );
  }
}
