import { useEffect } from "react";

const CHECK_INTERVAL = 60 * 1000; // 1 minute

export function useUpdateChecker() {
  useEffect(() => {
    async function checkForUpdates() {
      try {
        if ("serviceWorker" in navigator) {
          const registration = await navigator.serviceWorker.getRegistration();
          if (registration) {
            await registration.update();
          }
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
      }
    }

    const intervalId = setInterval(checkForUpdates, CHECK_INTERVAL);
    checkForUpdates();

    return () => {
      clearInterval(intervalId);
    };
  }, []);
}
