import {
  Instruction,
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

import { expandTask, moveTask } from "entities/tasks";
import { TaskModel } from "entities/tasks/model/task-model";

interface UseTaskDragProps {
  task: TaskModel;
  index: number[];
  isLast: boolean;
}

export function useTaskDrag({ task, index, isLast }: UseTaskDragProps) {
  const [lastDragOver, setLastDragOver] = useState<DateTime | null>(null);
  const [instruction, setInstruction] = useState<Instruction | null>(null);
  const [taskElement, setTaskElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!taskElement) return;

    return combine(
      draggable({
        element: taskElement,
        getInitialData: () => ({ type: "task", id: task.id, task }),
      }),
      dropTargetForElements({
        element: taskElement,
        getIsSticky: () => true,
        getData: ({ input, element }) =>
          attachInstruction(
            {},
            {
              input,
              element,
              currentLevel: index.length - 1,
              indentPerLevel: 0,
              mode: isLast
                ? "last-in-group"
                : task.isCollapsed
                  ? "standard"
                  : "expanded",
            },
          ),
        onDrag: async ({ self }) => {
          setInstruction(extractInstruction(self.data));

          if (
            lastDragOver &&
            DateTime.now().diff(lastDragOver).milliseconds > 1000 &&
            task.isParent &&
            task.isCollapsed
          )
            await expandTask(task.task);
        },
        onDragEnter: ({ self }) => {
          setInstruction(extractInstruction(self.data));
          setLastDragOver(DateTime.now());
        },
        onDragLeave: () => {
          setInstruction(null);
          setLastDragOver(null);
        },
        onDrop: ({ source }) => {
          const sourceTask = source.data.task as TaskModel;
          if (!sourceTask) return;

          const taskParent = task.parent?.task ?? null;
          const taskSiblingIndex = task.siblings.findIndex(
            (t) => t.id === task.id,
          );
          const sourceTaskSiblingIndex = task.siblings.findIndex(
            (t) => t.id === sourceTask.id,
          );
          const isSourceTaskEarlierThanTarget =
            sourceTaskSiblingIndex !== -1 &&
            sourceTaskSiblingIndex < taskSiblingIndex;

          switch (instruction?.type) {
            case undefined:
              break;
            case "make-child":
              if (source.data.id !== task.id)
                void moveTask(sourceTask.task, task.task, 0);
              break;
            case "reorder-above":
              void moveTask(
                sourceTask.task,
                taskParent,
                taskSiblingIndex - (isSourceTaskEarlierThanTarget ? 1 : 0),
              );
              break;
            case "reorder-below":
              void moveTask(
                sourceTask.task,
                taskParent,
                taskSiblingIndex + 1 - (isSourceTaskEarlierThanTarget ? 1 : 0),
              );
              break;
            default:
              throw new Error(`Invalid instruction type ${instruction?.type}`);
          }

          setInstruction(null);
          setLastDragOver(null);
        },
      }),
    );
  }, [
    index.length,
    instruction,
    isLast,
    lastDragOver,
    task,
    task.isCollapsed,
    taskElement,
  ]);

  return { instruction, setTaskElement };
}
